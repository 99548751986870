import facebookLogoUrl from "components/Seo/tecimob-facebook.jpg";

/**
 * Monta os dados do Facebook OpenGraph
 * @param siteUrl
 * @param description
 * @returns {*[]}
 */
export function facebookOpenGraph({ siteUrl, description }) {
  return [
    { property: "og:image", content: facebookLogoUrl },
    { property: "og:image:width", content: "300" },
    { property: "og:image:height", content: "300" },
    { property: "og:url", content: siteUrl },
    { property: `og:description`, content: description },
    { property: "og:site_name", content: "Tecimob" },
    { property: "og:locale", content: "pt-BR" },
    { property: `og:type`, content: "website" },
  ];
}

/**
 * Monta os dados do card de twitter
 * @param title
 * @param description
 * @param author
 * @param siteUrl
 * @returns {*[]}
 */
export function twitterCard({ title, description, author, siteUrl }) {
  return [
    { name: "twitter:card", content: "summary" },
    { name: "twitter:site", content: "@tecimob" },
    { name: "twitter:creator", content: author },
    { name: "twitter:url", content: siteUrl },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
  ];
}

/**
 * Monta os dados dos dados estruturados
 * @param json
 * @param url
 * @param title
 * @param image
 */
export function schemaJSON({ json, url, title, image }) {
  const jsonObject = [
    {
      "@context": "http://schema.org",
      "@type": "Website",
      "@id": "https://tecimob.com.br",
      sameAs: [
        "https://www.instagram.com/tecimob/",
        "https://www.facebook.com/tecimob",
        "https://twitter.com/tecimob",
        "https://www.linkedin.com/company/tecimob/",
      ],
      video: {
        "@type": "VideoObject",
        actor: {
          "@type": "Person",
          gender: "female",
          name: "Sibele Scremin",
          jobTitle: "Corretora de imóveis",
          telephone: "(48) 99612-0764",
          image:
            "https://d1wtgsutamk1fj.cloudfront.net/media/8852fdeb-ff1d-4697-aa47-4c61b09e470c/settings/600x450/outside/about/e5c02aac-ebe4-452b-9ab4-c9c207b0b4de/ubeP4EtP0hkzz9DgYwA2kHSZimA25Baz3kg9FRLX.jpeg",
        },
        embedUrl: "https://youtu.be/Nxgm88u2Gjk",
        description:
          "Um vídeo institucional aonde mostra os benefícios do Tecimob",
        thumbnailUrl: "https://tecimob.com.br/video-thumbnail-sibele.png",
        name: "Vídeo Institucional",
        uploadDate: "10/09/2019",
      },
    },
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "@id": "https://tecimob.com.br",
      name: "Tecimob",
      url: "https://tecimob.com.br",
      logo: {
        "@type": "ImageObject",
        url: "https://tecimob.com.br/logo-tecimob.svg",
        width: 400,
        height: 400,
      },
      email: "suporte@tecimob.com.br",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Rua Simeão Esmeraldino de Menezes, 400",
        addressLocality: "Tubarão (Santa Catarina)",
        addressRegion: "Santa Catarina",
        postalCode: "88704-090",
      },
      review: [
        {
          "@type": "Review",
          reviewRating: {
            "@type": "Rating",
            ratingValue: "5",
            bestRating: "5",
          },
          author: {
            "@type": "Organization",
            name: "Imobiliária Radar",
          },
        },
      ],
      openingHoursSpecification: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          opens: "09:00",
          closes: "17:00",
        },
      ],
    },
    ...json,
  ];

  return JSON.stringify(jsonObject);
}
