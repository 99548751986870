/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// Helpers
import { twitterCard, facebookOpenGraph, schemaJSON } from "./helpers";

function SEO({ titleTemplate, title, description, lang, meta, ldJson }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const siteUrl = site.siteMetadata.siteUrl;
  const metaDescription = description || site.siteMetadata.description;

  // Configurações Facebook Open Graph
  const Facebook = facebookOpenGraph({
    siteUrl,
    description: metaDescription
  });

  // Configurações do card do twitter
  const Twitter = twitterCard({
    title,
    siteUrl,
    author: site.siteMetadata.author,
    description: metaDescription
  });

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={titleTemplate || `%s - ${site.siteMetadata.title}`}
      meta={[
        { name: `description`, content: metaDescription },
        ...Facebook,
        ...Twitter,
        ...meta
      ]}
    >
      <script type="application/ld+json">
        {schemaJSON({ json: ldJson, url: siteUrl })}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  title: null,
  lang: `pt-BR`,
  meta: [],
  description: ``,
  ldJson: ``
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  ldJson: PropTypes.string
};

export default SEO;
